<script lang="ts">
  import { type CardProps } from "../content/Card.svelte";
  import SectionDivider from "../layout/SectionDivider.svelte";
  import { type EventEntry } from "../../types/entries.d.ts";
  import { convertStatamicAssetToCDN } from "@utils/general";
  import CardSlider from "@components/content/CardSlider.svelte";

  export let title: string;
  export let events: EventEntry[];

  function getRelatedContentUrl(content: EventEntry): string {
    //Item has a related entry
    if (content?.related_content?.url) {
      return content?.related_content?.url;
    }
    // Item has an external link
    if (content?.related_content) {
      return content?.related_content;
    }
    // Default to the item's url
    return content.url || "";
  }

  function convertEventEntryToCardProps(content: EventEntry): CardProps {
    return {
      tag: content?.event_type?.title || content.blueprint?.title,
      title: content?.title,
      description: content.subtitle,
      featuredImageSrc:
        convertStatamicAssetToCDN(content?.images?.wide?.id) || "",
      details: content.topics.map((topic) => topic.title).join(" • "),
      href: getRelatedContentUrl(content),
      rightCTA: {
        text: "Details & Locations",
        leftIcon: {
          name: "arrow-right",
          width: 12,
          height: 12,
        },
      },
    };
  }
</script>

<SectionDivider {title}>
  <CardSlider cards={events.map(convertEventEntryToCardProps)} />
</SectionDivider>
